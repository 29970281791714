export default {
    data(){
        return {
            deleteItemId: null,
            deleteItemText: null,
            deleteDialog: false,
            deleteRoute: '',
        }
    },
    created() {
    },
    methods: {
        deleteDialogShow(id, text='') {
            this.deleteItemId = id;
            this.deleteItemText = text;
            this.deleteDialog = true;
        },
        deleteDialogClosed(){
            this.deleteDialog = false;
            this.deleteItemId = null;
            this.deleteItemText = '';
        },
        deleteDialogDeleted(){
            this.deleteDialog = false;
            this.deleteItemId = null;
            this.deleteItemText = '';
            this.deleted();
        },
        deleted(){
            this.initialize();
        },
    },
    computed: {

    }

}
