<template>
    <v-card
        :loading="!(item && options)"
    >
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row v-if="item && options">
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="item.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                        <v-select
                            :items="options.languages"
                            :label="trans('fields.common.language')"
                            :multiple="false"
                            v-model="item.language_id"
                            :error-messages="errors.language_id"
                            @input="clearError('language_id')"
                        ></v-select>
                        <v-select
                            :items="options.countries"
                            :label="trans('fields.common.country')"
                            :multiple="false"
                            v-model="item.country_id"
                            :error-messages="errors.country_id"
                            @input="clearError('country_id')"
                        ></v-select>
                        <user-autocomplete
                            v-model="item.user_id"
                            :label="trans('fields.common.user')"
                            :error-messages="errors.user_id"
                            @input="clearError('user_id')"
                        ></user-autocomplete>
                        <v-select
                            v-if="options.landingDesigns"
                            :items="options.landingDesigns"
                            :label="trans('fields.common.landingDesign')"
                            :multiple="false"
                            v-model="item.design_id"
                            @change="designChanged"
                            :error-messages="errors.design_id"
                            @input="clearError('design_id')"
                        ></v-select>
                        <v-select
                            v-if="options.designDatas"
                            :items="options.designDatas"
                            :label="trans('fields.common.landingDesignData')"
                            :multiple="false"
                            v-model="item.design_data_id"
                            :error-messages="errors.design_data_id"
                            @input="clearError('design_data_id')"
                        ></v-select>

                        <v-divider class="mt-4 mb-4"></v-divider>
                        <v-row>
                            <v-subheader>{{ trans('pages.landing.userTracking') }}</v-subheader>
                        </v-row>

                        <v-text-field
                            v-model="item.yandex_counter_id"
                            :label="trans('fields.common.yandex_counter_id')"
                            :error-messages="errors['yandex_counter_id']"
                            @input="clearError('yandex_counter_id')"
                        ></v-text-field>
                        <v-text-field
                            v-model="item.google_counter_id"
                            :label="trans('fields.common.google_counter_id')"
                            :error-messages="errors['google_counter_id']"
                            @input="clearError('google_counter_id')"
                        ></v-text-field>
                        <v-text-field
                            v-model="item.facebook_pixel_id"
                            :label="trans('fields.common.facebook_pixel_id')"
                            :error-messages="errors['facebook_pixel_id']"
                            @input="clearError('facebook_pixel_id')"
                        ></v-text-field>
                        <v-text-field
                            v-model="item.vk_pixel_id"
                            :label="trans('fields.common.vk_pixel_id')"
                            :error-messages="errors['vk_pixel_id']"
                            @input="clearError('vk_pixel_id')"
                        ></v-text-field>
                        <v-list two-line>
                            <v-list-item>
                                <template v-slot:default="">
                                    <v-list-item-action>
                                        <v-switch v-model="item.settingsArray.utmByPassSettings.byPass"
                                        ></v-switch>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="trans('pages.landing.utmByPass.label')"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                            <v-list-item v-if="item.settingsArray.utmByPassSettings.byPass">
                                <template v-slot:default="">
                                    <v-list-item-action>
                                        <v-switch v-model="item.settingsArray.utmByPassSettings.custom"
                                        ></v-switch>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="trans('pages.landing.utmByPassCustom.label')"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list>
                        <v-list v-if="item.settingsArray.utmByPassSettings.byPass && item.settingsArray.utmByPassSettings.custom">
                            <v-list-item v-for="(field, index) in item.settingsArray.utmByPassSettings.fields" :key="index">
                                <template v-slot:default="">
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col cols="12" sm="6" class="pt-0 pb-0">
                                                <v-text-field
                                                        dense
                                                        class="ma-0"
                                                        v-model="item.settingsArray.utmByPassSettings.fields[index].incoming"
                                                        :label="trans('fields.landing.fields.incoming')"
                                                        @change="clearError('settingsArray.utmByPassSettings.fields.'+index+'.incoming')"
                                                        :error-messages="errors ? getErrors('settingsArray.utmByPassSettings.fields.'+index+'.incoming') : []"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="pt-0 pb-0">
                                                <v-text-field
                                                        dense
                                                        class="ma-0"
                                                        v-model="item.settingsArray.utmByPassSettings.fields[index].outgoing"
                                                        :label="trans('fields.landing.fields.outgoing')"
                                                        @change="clearError('settingsArray.utmByPassSettings.fields.'+index+'.outgoing')"
                                                        :error-messages="errors ? getErrors('settingsArray.utmByPassSettings.fields.'+index+'.outgoing') : []"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn @click="removeCustomField(index)" icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                            <v-row>
                                <v-col>
                                    <v-btn outlined color="primary" @click="addCustomField">{{ trans('buttons.landing.addParam') }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-list>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-dialog fullscreen v-model="offerSelectForm" max-width="800px">
                            <offer-select-form
                                v-if="offerSelectFormLoaded"
                                :landing_id="item.id"
                                :user_id="item.user_id"
                                :language_id="item.language_id"
                                :country_id="item.country_id"
                                :landing_design_id="item.design_id"
                                v-on:saved="closeOfferSelectForm"
                                v-on:close="closeOfferSelectForm"
                            ></offer-select-form>
                        </v-dialog>

                        <v-list two-line>
                            <draggable v-if="item && item.offer_appearances.length" :list="item.offer_appearances">
                                <transition-group name="flip-list">
                                    <v-card
                                            v-for="(offerAppearance, index) in item.offer_appearances"
                                            :key="offerAppearance.id"
                                            class="mb-3"
                                            elevation="1"
                                            style="min-height: 32px"
                                    >
                                        <v-list-item
                                                dense
                                                style="min-height: 32px"
                                        >
                                            <v-list-item-icon class="my-1">
                                                <v-icon class="draggable-handle draggable">mdi-drag</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ options.offersKeyed[offerAppearance.offer_id] }}
                                                    <offer-appearance-form
                                                            :user_id="item.user_id"
                                                            :parentOptions="options"
                                                            :parentItem="offerAppearance"
                                                            :parent-errors="errors.offer_appearances ? errors.offer_appearances[index] : {}"
                                                    />
                                                </v-list-item-title>

                                            </v-list-item-content>
                                            <v-list-item-icon class="my-1">
                                                <v-icon
                                                        small
                                                        @click="deleteDialogShow(offerAppearance.id, options.offersKeyed[offerAppearance.offer_id])"
                                                >mdi-delete</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                    </v-card>
                                </transition-group>
                            </draggable>
                        </v-list>
                        <v-btn
                            v-if="item.id"
                            @click="showOfferSelectForm"
                        >{{ trans('buttons.common.addOffers') }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <delete-dialog
                :dialogTrigger="deleteDialog"
                :item-id="deleteItemId"
                :delete-route="deleteRoute"
                @closed="deleteDialogClosed"
                @deleted="deleteDialogDeleted"
        >
            <template v-slot:title>
                {{ trans('pages.landing.deleteDialogTitle') }}
            </template>
            <template v-slot:text>
                {{ trans('pages.landing.deleteDialogText', {id: deleteItemText ? '"'+deleteItemText+'"' : deleteItemId }) }}
            </template>
        </delete-dialog>

        <v-card-actions v-if="item && options">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.close') }}</v-btn>
            <v-btn v-if="item.id" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    import UserAutocomplete from "../user/UserAutocomplete";
    import DeleteDialog from "@/components/commonComponents/DeleteDialog";
    import deleteDialog from "@/mixins/deleteDialog";
    import draggable from 'vuedraggable'

    export default {
        name: "LandingForm",
        mixins: [formValidate, deleteDialog],
        components: {
            OfferSelectForm: () => import('@/components/admin/offer/OfferSelectForm'),
            OfferAppearanceForm: () => import('@/components/admin/offerAppearance/OfferAppearanceForm'),
            UserAutocomplete,
            DeleteDialog,
            draggable
        },
        props: {
            itemId: {
                type: [Number, String],
            },
        },
        data(){
            return {
                showRoute: 'admin.landings.show',
                item: {
                    id: null,
                    label: '',
                    language_id: null,
                    country_id: null,
                    user_id: null,
                    design_id: null,
                    design_data_id: null,
                    offer_appearances: [],
                    yandex_counter_id: null,
                    google_counter_id: null,
                    facebook_pixel_id: null,
                    vk_pixel_id: null,
                    settingsArray:{
                        hideElements: {},
                        utmByPassSettings: {
                            byPass: false,
                            custom: false,
                            fields: [],
                        },
                    },
                },
                error: null,
                options: null,

                deleteRoute: 'admin.offerAppearances.delete',

                offerSelectForm: false,
                offerSelectFormLoaded: false,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                let id = this.itemId || this.item.id;
                if (id) {
                    axios.get(this.route(this.showRoute, id)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    }).catch(err => {
                        this.error = err.response.data;
                    });
                } else {
                    this.loadOptions();
                }
            },
            initialize(){
                this.load();
            },
            addCustomField(){
                this.item.settingsArray.utmByPassSettings.fields.push({
                    incoming: '',
                    outgoing: '',
                });
            },
            removeCustomField(index){
                if (index > -1) {
                    this.item.settingsArray.utmByPassSettings.fields.splice(index, 1);
                }
            },

            showOfferSelectForm(){
                this.offerSelectForm = true;
                this.offerSelectFormLoaded = true;
            },
            closeOfferSelectForm() {
                this.load();
                this.offerSelectForm = false;
                setTimeout(() => {
                    this.offerSelectFormLoaded = false;
                }, 300);
            },


            loadOptions(){
                axios.post(this.route('admin.options'), {
                    languages: true,
                    countries: true,
                    landingDesigns: true,
                    cpaNetworks: this.item ? {user_id: this.item.user_id} : null,
                    designDatas: this.item ? {design_id: this.item.design_id, language_id: this.item.language_id} : null,
                    offers: this.item ? this.item.user_id : null,
                }).then( response => {
                    this.options = response.data;
                })
            },
            designChanged(){
                this.item.design_data_id = null;
                this.loadOptions();
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route('admin.landings.store'), this.item).then((response) => {
                        console.log('save success');
                        this.item = response.data;
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.landings.update', this.item.id), this.item).then(() => {
                        console.log('save success');
                        this.$emit('updated');
                    }).catch( (err) => {
                        let errors = {};
                        _.each(err.response.data.errors, (list, name) => {
                            _.set(errors, name, list);
                        });
                        this.errors = errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.item.id
                    ? this.trans('pages.landing.newDialogTitle')
                    : this.trans('pages.landing.editDialogTitle');
            },
        }
    }
</script>
